import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { v4 as uuidv4 } from 'uuid';

import { tokenUpdate } from '../../atoms/authModalControl';
import { cartAtom } from '../../atoms/Cart';
import { localCart } from '../../atoms/LocalCart';
import { localUserID, user } from '../../atoms/user';
import { formatCartData } from '../../components/CartPage';
import decryptToken from '../../components/shared/crypto/decryptToken';
import { formateErrorObject } from '../../components/shared/formateErrorObject';
import {
  removeUserId,
  removeZl_UID,
  setToken,
  setUserId,
  setUserName,
  setZl_UID,
} from '../../components/shared/getToken';
import { trackAddToCartEvent } from '../../components/shared/Gtags';
import CustomLoader from '../../components/shared/Loading';
import { notify } from '../../components/shared/notify';
import appwriteService, { setTokenInCookies } from '../../config/appwrite';
import { appWriteUser } from '../../hooks/useAuth';
import { affiliateLinkClicks } from '../../services/Affiliate';
import {
  addCouponCode,
  addToCart,
  addToRecentlyViewed,
  getCartCount,
  getCartDetails,
} from '../../services/Cart';
import { createSecret } from '../../services/Library/Books';
import { AppwriteUserData, User } from '../../utils/interfaces/User';

interface Props {
  product_id: string | string[] | undefined;
  redirect_url: string | string[] | undefined;
  afc: string | string[] | undefined;
  buy_now: string | string[] | undefined;
  cp: string | string[] | undefined;
  ref_code: string | string[] | undefined;
}

const RedirectPage: React.FC<Props> = ({
  product_id,
  redirect_url,
  afc,
  buy_now,
  cp,
  ref_code,
}) => {
  const [, setLocalCartCount] = useRecoilState(localCart);
  const [, setCartData] = useRecoilState(cartAtom);
  const [localUserId, setLocalUserId] = useRecoilState(localUserID);
  const [, setUserData] = useRecoilState<User | undefined>(user);
  const [isTokenChanged, setIsTokenChanged] = useRecoilState(tokenUpdate);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);
  const userData: User | undefined = useRecoilValue(user);
  const [isCalled, setIsCalled] = useState<boolean>(false);

  const router = useRouter();

  const isBuyNow = buy_now?.toString() === 'true';

  useEffect(() => {
    const fetchLoggedInStatus = async () => {
      const loggedInStatus = await appwriteService.isLoggedIn();
      setLoggedIn(loggedInStatus);
    };
    fetchLoggedInStatus();
    if (isTokenChanged) {
      setIsTokenChanged(false);
    }
  }, [appwriteService.account, isTokenChanged]);

  const handleCartCount = async (cartId?: number) => {
    try {
      const data = await getCartCount({ cartId });
      setLocalCartCount(data.count);
    } catch (error: any) {
      const message = formateErrorObject(error);
      notify(message, 'error');
    }
  };

  const handleCartDetails = async (cartId?: number) => {
    try {
      const data = await getCartDetails(localUserId.toString(), cartId);
      const formattedData = formatCartData(
        data?.data?.[0]?.cart_items,
        data?.cart_id,
      );
      setCartData(formattedData);
    } catch (error: any) {
      const message = formateErrorObject(error);
      notify(message, 'error');
    }
  };
  const handleCreateSecret = async (token: string | null) => {
    try {
      if (token) {
        const response = await createSecret();
        if (response?.zl_uid) {
          const responseAppwriteSession =
            await appwriteService.createSessionBySecretKey({
              user_id: response?.zl_uid,
              secret: response?.secret,
            });
          Cookies.remove('courseRedirectCookieToken');
          if (responseAppwriteSession) {
            const user = await appwriteService.getCurrentUser();
            let currentUser: AppwriteUserData | undefined = undefined;
            const token = await setTokenInCookies();
            if (user !== null) {
              currentUser = user as unknown as AppwriteUserData;
            }
            const dbUser: User = await appWriteUser(currentUser?.$id as string);
            await setToken(token as string);
            await setUserName(dbUser?.name);
            setUserData(dbUser);
            setIsTokenChanged(true);
            await setUserId(dbUser?.id?.toString());
            await setZl_UID(dbUser?.zl_uid?.toString() as string);
          }
        }
      }
    } catch (error) {
      console.error('Invalid token', error);
    }
  };

  const checkToken = async () => {
    let isSecretCreated = false;
    if (redirect_url && router.isReady && !isSecretCreated) {
      isSecretCreated = true;
      const CRYPTO_KEY = process.env.NEXT_PUBLIC_CRYPTO_KEY || '';

      const encrypt = encodeURIComponent(redirect_url as string);

      const decryptedToken = decryptToken(encrypt, CRYPTO_KEY);
      Cookies.set('courseRedirectCookieToken', decryptedToken as string);
      await handleCreateSecret(decryptedToken);
    }
  };

  const processCartItems = async () => {
    try {
      const items = (product_id as string)?.split(',') || [];
      let cartIdLocal;

      // Process each item sequentially
      for (const item of items) {
        const data = await addToCart(
          {
            id: Number(item),
            temp_user_id: localUserId.toString(),
            quantity: 1,
          },
          isBuyNow,
        );
        cartIdLocal = data?.data?.cart_id;
        addToRecentlyViewed(Number(product_id), userData?.zl_uid);
        trackAddToCartEvent({
          productData: {
            price: 0,
            id: Number(product_id),
            heading: '',
            quantity: 1 as number,
          },
          userData,
          pathname: router.pathname,
          productType: data?.type === 'hardcover' ? 'hardcopy' : 'digitalcopy',
        });
      }

      if (cp) {
        handleApplyCoupon(cp as string);
      }

      handleCartCount(isBuyNow && cartIdLocal);

      if (localUserId.toString()) {
        handleCartDetails(isBuyNow && cartIdLocal);
      }

      if (items.length > 0 && router.isReady) {
        if (isBuyNow) {
          router.push(`/checkout?cartId=${cartIdLocal}`);
        } else {
          router.push(`/cart/`);
        }
      }
    } catch (error: any) {
      const message = formateErrorObject(error);
      notify(message, 'error');
    }
  };

  const setUserIdInLocalStorage = async () => {
    const existingUserId = localStorage.getItem('userId');

    if (!existingUserId) {
      const userId = uuidv4();

      localStorage.setItem('userId', userId);
      setTimeout(() => {
        setLocalCartCount(0);
        removeUserId();
        removeZl_UID();
      }, 2000);

      setLocalUserId(userId);
    }
    setLocalUserId(existingUserId as string);
  };

  useEffect(() => {
    if (router.isReady) {
      if (product_id && !isCalled) {
        processCartItems();
        setIsCalled(true);
      }

      if (afc && !loggedIn) {
        handleLinkClicks(afc as string);
        Cookies.set('afc', afc as string, { expires: 7 });
      }
      if (ref_code) {
        Cookies.set('ref_code', ref_code as string, { expires: 30 });
      }
    }
  }, [product_id, localUserId, router, afc, isCalled, ref_code]);

  useEffect(() => {
    if (redirect_url && router.isReady) {
      checkToken();
    } else {
      setUserIdInLocalStorage();
    }
  }, [redirect_url, router]);

  const handleLinkClicks = async (afc: string) => {
    try {
      await affiliateLinkClicks(afc);
    } catch (error: any) {
      if (process.env.NEXT_PUBLIC_APP_ENV === 'development') {
        console.error('Error initializing messaging:', error);
      }
    }
  };

  const handleApplyCoupon = async (coupon: string) => {
    try {
      await addCouponCode(coupon as string);
    } catch (error: any) {
      if (process.env.NEXT_PUBLIC_APP_ENV === 'development') {
        console.error('Error initializing messaging:', error);
      }
    }
  };

  return <CustomLoader />;
};

export async function getServerSideProps(context: any) {
  const { query } = context;
  const product_id = query['add-to-cart'] || null;
  const afc = query['afc'] || '';
  const ref_code = query['ref_code'] || '';
  const buy_now = query['buy_now'] || '';
  const cp = query['cp'] || null;

  const redirect_url =
    query.redirect_url !== undefined ? query.redirect_url : null;

  return {
    props: {
      product_id,
      redirect_url,
      afc,
      buy_now,
      cp,
      ref_code,
    },
  };
}

export default RedirectPage;
